import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import Navbar from "../components/navbar"
import Footer from "../components/footer"

const StyledAboutMe = styled.div`
  padding: 3rem 1.5rem;
  
  @media(min-width: 800px) {
    padding: 4rem 10rem;
  }
`;

const AboutMeContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  
  @media(min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  
  @media(min-width: 800px) {
    width: 48%;
  }
`;

const StyledH3 = styled.h3`
  font-size: 2rem;
  font-weight: 500;
`;

const StyledH4 = styled.h4`
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-bottom: 0.7rem;
  
  @media(min-width: 800px) {
    margin: 1rem 0;
  }
`;

const Paragraph = styled.p`
  color: rgba(88, 88, 88, 0.96);
  font-size: 1.3rem;
`;

const StyledHr = styled.hr`
  background-color: rgba(230, 50, 50, 0.86);
  width: 90px;
  height: 6px;
  border: none;
  border-radius: 10px;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
`;

const ListItem = styled.li`
  color: rgba(88, 88, 88, 0.96);
  font-size: 1.3rem;
`;

const AboutMePageRender = ({ data }) => {
  return(
    <>
      <Navbar/>
      <StyledAboutMe>
        <StyledH3>{ data.datoCmsAboutMe.title }</StyledH3>
        <StyledHr/>
        <AboutMeContainer>
          { data.datoCmsAboutMe.moreInfo.map(val => {
            if (val.content.includes('*')) {
              let data = val.content.split('*')

              return <Item>
                <StyledH4>{ val.title }</StyledH4>
                <ul>
                  {data.map((v, i) => {
                    if (i !== 0)
                      return <ListItem>- {v}</ListItem>
                  })}
                </ul>
              </Item>;
            } else {
              return <Item>
                <StyledH4>{ val.title }</StyledH4>
                <Paragraph>{ val.content }</Paragraph>
              </Item>;
            }
          })}
        </AboutMeContainer>
      </StyledAboutMe>
      <Footer/>
    </>
  );
}

export default function AboutMePage() {
  return (
    <StaticQuery
      query={graphql`
        query AboutMePageQuery {
          datoCmsAboutMe {
            title
            moreInfo {
              title
              content
            }
          }
        }
      `}
      render={data => <AboutMePageRender data={data}/>}
    />
  )
};